import React from 'react';
import Layout from '@studionand/depi/src/components/DepiLayout';
import Editor from '@studionand/depi/src/components/depi/Editor';
import config from '@studionand/depi/src/components/depi/Header/constants';
import NamedPage from '@studionand/depi/src/components/NamedPage';

const DepiNamedPage = props => {
  return (
    <NamedPage
      {...props}
      config={config}
      layoutComponent={Layout}
      editorComponent={Editor}
    />
  );
};

DepiNamedPage.getInitialProps = NamedPage.getInitialProps(config);

export default DepiNamedPage;
